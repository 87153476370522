/* markdownStyles.css */
.markdown-container {
    color: #050505;
}

.markdown-container h1,
.markdown-container h2,
.markdown-container h3,
.markdown-container h4,
.markdown-container h5,
.markdown-container h6 {
    color: #050505;
}

/* Add the following CSS rules to style the hyperlinks */
.markdown-container a,
.markdown-container a:visited {
    color: #ee3d5b; /* Change this value to your desired color */
    text-decoration: none;
}

.markdown-container a:hover,
.markdown-container a:active {
    color: #15a2fa; /* Change this value to your desired color for hover and active states */
    text-decoration: underline;
}
